// import React, { useEffect, useState } from "react";
// import { Drawer } from "rsuite";
// import "../../assets/style/EmployeeProfile/ProfileDrawer.css";
// import ProfileAccordion from "./ProfileAccordion";
// import { Button, CoreLoader } from "common/components";
// import apiManager from "networking/baseAPIManager";
// import moment from "moment";
// import "../../assets/style/profileEditicon.css";
// import userImage from "../../assets/images/UserDefultImage.png";
// import { useDispatch } from "react-redux";
// import { triggerRefresh } from "redux/slice";

// const ProfileDrawer = ({ setOpen, data, open }) => {
//   const dispatch = useDispatch();

//   const [candidateData, setCandidateData] = useState({});
//   const [fileData, setFileData] = useState(null);
//   const [loader, setLoader] = useState(false);
//   const [errorMessage, setErrorMessage] = useState(""); // New state for error message

//   const BASE_URL = process.env.REACT_APP_BASE_URL;
//   const fullName = `${data.firstname} ${data.middlename} ${data.lastname}`;
//   const isOnLeave = data.start_date && data.end_date;
//   const empid = data?._id;

//   const dateOfJoin = moment(candidateData.createdAt).format("DD MMM YYYY");
//   const startDate = moment(data.start_date).format("DD MMM YYYY");
//   const endDate = moment(data.end_date).format("DD MMM YYYY");

//   useEffect(() => {
//     if (empid) {
//       getAllDataCandidate();
//     }
//   }, [empid]);

//   useEffect(() => {
//     if (fileData) {
//       changeProfilePicture();
//     }
//   }, [fileData]);

//   const getAllDataCandidate = () => {
//     setLoader(true);
//     apiManager
//       .candidateAllData(empid)
//       .then((res) => {
//         setCandidateData(res?.data);
//       })
//       .catch((err) => {
//         console.log(err, "API Error all Data");
//       })
//       .finally(() => {
//         setLoader(false);
//       });
//   };

//   const handleEditClick = () => {
//     const fileInput = document.getElementById("fileInput");
//     if (fileInput) {
//       fileInput.click();
//     }
//   };

//   const changeProfilePicture = () => {
//     if (fileData) {
//       let formData = new FormData();
//       formData.append("empid", empid);
//       formData.append("image", fileData);
//       apiManager
//         .updateEmployee(formData)
//         .then((response) => {
//           console.log(response, "response");
//           getAllDataCandidate();
//           setErrorMessage(""); // Clear error message on success
//           dispatch(triggerRefresh(true));
//         })
//         .catch((error) => {
//           console.log(error, "error");
//           setErrorMessage("Failed to update profile picture. Please try again."); // Set error message
//         });
//     }
//   };

//   const handleClose = () => {
//     setOpen(false);
//     dispatch(triggerRefresh(false));
//   };

//   return (
//     <>
//       <Drawer open={open} onClose={handleClose} size="sm">
//         <Drawer.Header className="drawer-Header">
//           <Drawer.Title className="d-flex justify-content-center">
//             <b>Employee Profile</b>
//           </Drawer.Title>
//         </Drawer.Header>
//         <Drawer.Body>
//           <div className="container-fluid d-flex flex-column h-100 mb-2">
//             <div className="px-1">
//               <div className="row mt-3">
//                 <div className="col-lg-6 justify-content-center">
//                   <img
//                     className="drawerUserImage"
//                     src={candidateData?.image ? `${BASE_URL}/${candidateData?.image}` : userImage}
//                   />
//                   <div className="profileEdit" onClick={handleEditClick}>
//                     <div>
//                       <input
//                         id="fileInput"
//                         type="file"
//                         style={{ display: "none" }}
//                         onChange={(e) => {
//                           setFileData(e.target.files[0]);
//                         }}
//                       />
//                       <span className="material-symbols-outlined pointer p-2 bg-primary-subtle border border-primary-subtle rounded-circle">
//                         edit
//                       </span>
//                     </div>
//                   </div>

//                   {/* Error message below the image */}
//                   {errorMessage && (
//                     <p className="text-danger mt-2" style={{ fontSize: "14px", textAlign: "center" }}>
//                       {errorMessage}
//                     </p>
//                   )}
//                 </div>
//                 <div className="col-lg-6">
//                   <h4>{fullName}</h4>
//                   <p>
//                     Employee Department:{" "}
//                     <span className="text-primary">{candidateData.department ? candidateData.department : "N/A"}</span>
//                   </p>
//                   <p>
//                     Employee ID:{" "}
//                     <span className="text-primary">{candidateData.employeeID ? candidateData.employeeID : "N/A"}</span>
//                   </p>
//                   <p className="text-primary">Date of Joining: {dateOfJoin}</p>
//                 </div>
//               </div>
//               <div className="row mt-4">
//                 <div className="col-lg-12">
//                   <ul className="drawerUserDetail ps-0">
//                     <li className="row">
//                       <span className="col-lg-4">Phone</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">{candidateData.mobile_number}</span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-4">Email</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">{candidateData.company_email}</span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-4">Birthday</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">
//                         {moment(candidateData.documentDob).format("DD MMM YYYY")}
//                       </span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-4">Office Location</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">
//                         {candidateData.worklocation &&
//                         candidateData.worklocation.cityname &&
//                         candidateData.worklocation.countryname
//                           ? `${candidateData.worklocation.cityname} (${candidateData.worklocation.countryname})`
//                           : "N/A"}
//                       </span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-4">Gender</span>
//                       <span className="col-lg-2">:</span>
//                       <span className="col-lg-4 text-dark-emphasis">{candidateData.gender}</span>
//                     </li>
//                     {isOnLeave && (
//                       <li className="row">
//                         <span className="col-lg-4">On Leave</span>
//                         <span className="col-lg-2">:</span>
//                         <span className="col-lg-4 text-dark-emphasis">
//                           {startDate} To {endDate}
//                         </span>
//                       </li>
//                     )}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//             <div className="mt-auto pb-4">
//               <ProfileAccordion data={candidateData} />
//             </div>
//             <div className="pb-2 d-flex justify-content-center">
//               <Button label="Close" onClick={handleClose} />
//             </div>
//           </div>
//         </Drawer.Body>
//       </Drawer>
//       <CoreLoader show={loader} disableLines />
//     </>
//   );
// };

// export default ProfileDrawer;

import React, { useEffect, useState } from "react";
import { Drawer } from "rsuite";
import "../../assets/style/EmployeeProfile/ProfileDrawer.css";
import ProfileAccordion from "./ProfileAccordion";
import { Button, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import moment from "moment";
import "../../assets/style/profileEditicon.css";
import userImage from "../../assets/images/UserDefultImage.png";
import { useDispatch } from "react-redux";
import { triggerRefresh } from "redux/slice";

const ProfileDrawer = ({ setOpen, data, open }) => {
  const dispatch = useDispatch();

  const [candidateData, setCandidateData] = useState({});
  const [fileData, setFileData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const fullName = `${data.firstname} ${data.middlename} ${data.lastname}`;
  const isOnLeave = data.start_date && data.end_date;
  const empid = data?._id;

  const dateOfJoin = moment(candidateData.createdAt).format("DD MMM YYYY");
  const startDate = moment(data.start_date).format("DD MMM YYYY");
  const endDate = moment(data.end_date).format("DD MMM YYYY");

  useEffect(() => {
    if (empid) {
      getAllDataCandidate();
    }
  }, [empid]);

  useEffect(() => {
    if (fileData) {
      validateAndUploadFile();
    }
  }, [fileData]);

  const getAllDataCandidate = () => {
    setLoader(true);
    apiManager
      .candidateAllData(empid)
      .then((res) => {
        setCandidateData(res?.data);
      })
      .catch((err) => {
        console.log(err, "API Error all Data");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleEditClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const validateAndUploadFile = () => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!allowedTypes.includes(fileData.type)) {
      setErrorMessage("Only JPEG, JPG, or PNG files are allowed.");
      return;
    }

    if (fileData.size > maxFileSize) {
      setErrorMessage("File size must be less than 2MB.");
      return;
    }

    // If validation passes, proceed to upload
    changeProfilePicture();
  };

  const changeProfilePicture = () => {
    if (fileData) {
      let formData = new FormData();
      formData.append("empid", empid);
      formData.append("image", fileData);

      apiManager
        .updateEmployee(formData)
        .then((response) => {
          getAllDataCandidate();
          setErrorMessage(""); // Clear error message on success
          dispatch(triggerRefresh(true));
        })
        .catch((error) => {
          console.log(error, "error");
          setErrorMessage("Failed to update profile picture. Please try again.");
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(triggerRefresh(false));
  };

  return (
    <>
      <Drawer open={open} onClose={handleClose} size="sm">
        <Drawer.Header className="drawer-Header">
          <Drawer.Title className="d-flex justify-content-center">
            <h4>Employee Profile</h4>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="container-fluid d-flex flex-column h-100 mb-2">
            <div className="px-1">
              <div className="row mt-3">
                <div className="col-lg-6 justify-content-center">
                  <img
                    className="drawerUserImage"
                    src={candidateData?.image ? `${BASE_URL}/${candidateData?.image}` : userImage}
                  />
                  <div className="profileEdit" onClick={handleEditClick}>
                    <div>
                      <input
                        id="fileInput"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setFileData(e.target.files[0]);
                        }}
                      />
                      <span className="material-symbols-outlined pointer p-2 bg-primary-subtle border border-primary-subtle rounded-circle">
                        edit
                      </span>
                    </div>
                  </div>

                  {/* Error message below the image */}
                  {errorMessage && (
                    <p className="text-danger mt-2" style={{ fontSize: "14px", textAlign: "center" }}>
                      {errorMessage}
                    </p>
                  )}
                </div>
                <div className="col-lg-6">
                  <h5>{fullName}</h5>
                  <p>
                    Employee Department:{" "}
                    <span className="text-primary">{candidateData.department ? candidateData.department : "N/A"}</span>
                  </p>
                  <p>
                    Employee ID:{" "}
                    <span className="text-primary">{candidateData.employeeID ? candidateData.employeeID : "N/A"}</span>
                  </p>
                  <p className="text-primary">Date of Joining: {dateOfJoin}</p>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <ul className="drawerUserDetail ps-0">
                    <li className="row">
                      <span className="col-lg-4">Phone</span>
                      <span className="col-lg-2">:</span>
                      <span className="col-lg-4 text-dark-emphasis">{candidateData.mobile_number}</span>
                    </li>
                    <li className="row">
                      <span className="col-lg-4">Email</span>
                      <span className="col-lg-2">:</span>
                      <span className="col-lg-4 text-dark-emphasis">{candidateData.company_email}</span>
                    </li>
                    <li className="row">
                      <span className="col-lg-4">Birthday</span>
                      <span className="col-lg-2">:</span>
                      <span className="col-lg-4 text-dark-emphasis">
                        {moment(candidateData.documentDob).format("DD MMM YYYY")}
                      </span>
                    </li>
                    <li className="row">
                      <span className="col-lg-4">Office Location</span>
                      <span className="col-lg-2">:</span>
                      <span className="col-lg-4 text-dark-emphasis">
                        {candidateData.worklocation &&
                        candidateData.worklocation.cityname &&
                        candidateData.worklocation.countryname
                          ? `${candidateData.worklocation.cityname} (${candidateData.worklocation.countryname})`
                          : "N/A"}
                      </span>
                    </li>
                    <li className="row">
                      <span className="col-lg-4">Gender</span>
                      <span className="col-lg-2">:</span>
                      <span className="col-lg-4 text-dark-emphasis">{candidateData.gender}</span>
                    </li>
                    {isOnLeave && (
                      <li className="row">
                        <span className="col-lg-4">On Leave</span>
                        <span className="col-lg-2">:</span>
                        <span className="col-lg-4 text-dark-emphasis">
                          {startDate} To {endDate}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-auto pb-4">
              <ProfileAccordion data={candidateData} />
            </div>
            <div className="pb-2 d-flex justify-content-center">
              <Button label="Close" onClick={handleClose} />
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
      <CoreLoader show={loader} disableLines />
    </>
  );
};

export default ProfileDrawer;
