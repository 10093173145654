import React, { useEffect, useRef, useState } from "react";
import uploadImage from "../../assets/images/Vector (11).png";
import apiManager from "networking/baseAPIManager";
import Button from "./Button";
import CoreLoader from "./Loader";

export default function BulkAssetModal({ setmsgType, setShowMSG, setOpen }) {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (e) => {
    const file = e.target.files ? e.target.files[0] : null;

    // Reset error message
    setErrorMsg("");

    // If no file is selected, return early
    if (!file) {
      setErrorMsg("No file selected. Please choose an Excel file.");
      setSelectedFile(null);
      return;
    }

    // Validate file type (only accept .xls or .xlsx)
    const validFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    if (!validFileTypes.includes(file.type)) {
      setErrorMsg("Please upload an Excel file (xls or xlsx).");
      setSelectedFile(null);
      return;
    }

    // Validate file size (below 1MB)
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes
    if (file.size > maxSize) {
      setErrorMsg("File size must be less than 1MB.");
      setSelectedFile(null);
      return;
    }

    // Set the file if validation passes
    setSelectedFile(file);
  };

  const bulkAssetsDetail = () => {
    if (!selectedFile) {
      setErrorMsg("Please select a valid Excel file to upload.");
      return;
    }

    let formdata = new FormData();
    formdata.append("excel", selectedFile);
    setLoading(true);

    apiManager
      .bulkAsset(formdata)
      .then((response) => {
        setmsgType("success");
        setShowMSG(response?.data?.message || "File uploaded successfully");
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setmsgType("error");
        setShowMSG(error?.response?.data?.message || "Failed to upload file");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="container pt-3">
        <div className="row px-5 text-center">
          <div
            className="pt-3 m-auto"
            style={{
              border: "solid 1px",
              borderRadius: "6px",
              height: "180px",
              width: "50%",
            }}
            onClick={handleUploadClick}
          >
            <div className=" ">
              <img src={uploadImage} alt="File Upload Icon" />
            </div>
            <div className="pt-5">Upload Bulk Data (Excel)</div>
          </div>
          <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileSelect} />
          {selectedFile && <div className="pt-3">Selected File: {selectedFile.name}</div>}
          {errorMsg && <div className="text-danger pt-3">{errorMsg}</div>}
          <div className="pt-5 text-align-center justify-content-center d-flex">
            <Button label="Send" onClick={bulkAssetsDetail} />
          </div>
        </div>
        <CoreLoader show={loading} disableLines />
      </div>
    </>
  );
}
