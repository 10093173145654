// import { AlertMessage, Button, CommonModal, CoreLoader } from "common/components";
// import ConfirmationModal from "common/components/ConfirmationModal";
// import apiManager from "networking/baseAPIManager";
// import React, { useEffect, useState } from "react";

// const AdvanceSalaryView = ({ Data, setOpen, fetchapi }) => {
//   const [loading, setLoading] = useState(false);
//   const [showMSG, setShowMSG] = useState(false);
//   const [msgType, setmsgType] = useState("info");
//   const [cancele_id, setCancel_id] = useState("");
//   const [confirm, setConfirm] = useState(false);
//   const [WithdrawModalOpen, setWithdrawModalOpen] = useState(false);

//   let BASE_URL = process.env.REACT_APP_BASE_URL;
//   const employeeInfo = Data;
//   const moment = require("moment");

//   // Function to format dates
//   const formatDate = (date) => moment(date).format("L");

//   const secLabels = {
//     createdAt: "Applied Date",
//     amount: "Amount",
//     model: "Duration",
//     instalment: "Installment",
//     advance_salary_type: "Salary Type",
//     reason: "Reason",
//   };

//   const openWithdrawModal = () => {
//     setWithdrawModalOpen(true);
//     setCancel_id(Data._id);
//   };

//   const handleStatusChange = () => {
//     let dataPayload = {
//       id: cancele_id,
//       status: "cancelled",
//     };
//     setLoading(true);
//     apiManager
//       .advanceSalaryStatus(dataPayload)
//       .then((res) => {
//         setLoading(false);
//         fetchapi && fetchapi();
//         setmsgType("success");
//         setShowMSG(res?.data?.message || "details updated successfully");
//       })
//       .catch((err) => {
//         setLoading(false);
//         setmsgType("error");
//         setShowMSG(err?.response?.data?.message || "failed to update");
//       });
//   };

//   const arr = employeeInfo
//     ? Object.entries(employeeInfo)
//         .filter(([originalLabel]) => secLabels[originalLabel])
//         .map(([originalLabel, value]) => ({
//           label: secLabels[originalLabel],
//           value: originalLabel === "createdAt" ? formatDate(value) : value,
//         }))
//     : [];

//   useEffect(() => {
//     if (confirm) {
//       handleStatusChange();
//       setConfirm(false);
//     }
//   }, [confirm]);

//   const getStatusClass = (status) => {
//     switch (status) {
//       case "pending":
//         return "text-info";
//       case "approved":
//         return "text-success";
//       default:
//         return "text-danger";
//     }
//   };

//   return (
//     <div className="container">
//       {/* <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} /> */}
//       <div className="row">
//         <div className="col-lg-6">
//           <div className="row">
//             {employeeInfo ? (
//               <>
//                 <div className="col-lg-12 ps-5">
//                   <img
//                     src={`${BASE_URL}/${employeeInfo.image}`}
//                     alt="Asset"
//                     width="200"
//                     height="200"
//                     className="rounded-circle"
//                   />
//                 </div>
//                 <div className="col-lg-12">
//                   <ul>
//                     <li className="row">
//                       <span className="col-lg-6 pt-4">Employee ID </span>
//                       <span className={`col-lg-6 pt-3 `}>{employeeInfo.employeeID}</span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-6 pt-4"> Employee Name</span>
//                       <span className="col-lg-6 text-dark-emphasis pt-3">{employeeInfo.employee_name}</span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-6 pt-4"> Status </span>
//                       <span className={`col-lg-6 text-dark-emphasis pt-3 ${getStatusClass(employeeInfo.status)}`}>
//                         {employeeInfo.status}
//                       </span>
//                     </li>
//                   </ul>
//                 </div>
//               </>
//             ) : null}
//           </div>
//         </div>
//         <div className="col-lg-6 pt-3">
//           {arr &&
//             arr.map((content, index) => (
//               <div className="row " key={index}>
//                 <div className="col-lg-6 pt-4  ">{content.label} </div>
//                 <div className="col-lg-6 pt-4 text-dark-emphasis ">{content.value}</div>
//               </div>
//             ))}
//         </div>
//       </div>

//       {Data && Data.status === "approved" && (
//         <div className="row px-5">
//           <div className="col-md-10 p-2"></div>
//           <div className="col-md-2 p-2">
//             <Button label="withdraw" onClick={() => openWithdrawModal()}></Button>
//           </div>

//           {WithdrawModalOpen && (
//             <div className="col-md-12 drawpop p-2">
//               <ConfirmationModal setOpen={setOpen} setConfirm={setConfirm} />
//             </div>
//           )}
//           <CoreLoader show={loading} disableLines />
//         </div>
//       )}
//     </div>
//   );
// };

// export default AdvanceSalaryView;
// import { Button, CoreLoader } from "common/components";
// import ConfirmationModal from "common/components/ConfirmationModal";
// import apiManager from "networking/baseAPIManager";
// import React, { useEffect, useState } from "react";

// const AdvanceSalaryView = ({ Data, setOpen, fetchapi }) => {
//   const [loading, setLoading] = useState(false);
//   const [showMSG, setShowMSG] = useState(false);
//   const [msgType, setmsgType] = useState("info");
//   const [cancele_id, setCancel_id] = useState("");
//   const [confirm, setConfirm] = useState(false);
//   const [WithdrawModalOpen, setWithdrawModalOpen] = useState(false);

//   const BASE_URL = process.env.REACT_APP_BASE_URL;
//   const employeeInfo = Data;
//   const moment = require("moment");

//   // Function to format dates
//   const formatDate = (date) => moment(date).format("L");

//   const secLabels = {
//     createdAt: "Applied Date",
//     amount: "Amount",
//     model: "Duration",
//     instalment: "Installment",
//     advance_salary_type: "Salary Type",
//     reason: "Reason",
//   };

//   const openWithdrawModal = () => {
//     setWithdrawModalOpen(true);
//     setCancel_id(Data._id);
//   };

//   const handleStatusChange = () => {
//     let dataPayload = {
//       id: cancele_id,
//       status: "cancelled",
//     };
//     setLoading(true);
//     apiManager
//       .advanceSalaryStatus(dataPayload)
//       .then((res) => {
//         setLoading(false);
//         fetchapi && fetchapi();
//         setmsgType("success");
//         setShowMSG(res?.data?.message || "Details updated successfully");
//       })
//       .catch((err) => {
//         setLoading(false);
//         setmsgType("error");
//         setShowMSG(err?.response?.data?.message || "Failed to update");
//       });
//   };

//   const arr = employeeInfo
//     ? Object.entries(employeeInfo)
//         .filter(([originalLabel]) => secLabels[originalLabel])
//         .map(([originalLabel, value]) => ({
//           label: secLabels[originalLabel],
//           value: originalLabel === "createdAt" ? formatDate(value) : value,
//         }))
//     : [];

//   useEffect(() => {
//     if (confirm) {
//       handleStatusChange();
//       setConfirm(false);
//     }
//   }, [confirm]);

//   const getStatusClass = (status) => {
//     switch (status) {
//       case "pending":
//         return "text-info";
//       case "approved":
//         return "text-success";
//       default:
//         return "text-danger";
//     }
//   };

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-6">
//           <div className="row">
//             {employeeInfo ? (
//               <>
//                 <div className="col-lg-12 ps-5">
//                   <img
//                     src={`${BASE_URL}/${employeeInfo.image}`}
//                     alt="Asset"
//                     width="200"
//                     height="200"
//                     className="rounded-circle"
//                   />
//                 </div>
//                 <div className="col-lg-12">
//                   <ul>
//                     <li className="row">
//                       <span className="col-lg-6 pt-4">Employee ID</span>
//                       <span className="col-lg-6 pt-3">{employeeInfo.employeeID}</span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-6 pt-4">Employee Name</span>
//                       <span className="col-lg-6 pt-3">{employeeInfo.employee_name}</span>
//                     </li>
//                     <li className="row">
//                       <span className="col-lg-6 pt-4">Status</span>
//                       <span className={`col-lg-6 pt-3 ${getStatusClass(employeeInfo.status)}`}>
//                         {employeeInfo.status}
//                       </span>
//                     </li>
//                   </ul>
//                 </div>
//               </>
//             ) : null}
//           </div>
//         </div>
//         <div className="col-lg-6 pt-3">
//           {arr &&
//             arr.map((content, index) => (
//               <div className="row" key={index}>
//                 <div className="col-lg-6 pt-4">{content.label}</div>
//                 <div
//                   className={`col-lg-6 pt-4 text-dark-emphasis ${content.label === "Reason" ? "text-wrap-break" : ""}`}
//                 >
//                   {content.value}
//                 </div>
//                 <div className="col-lg-6 pt-4">{content.label}</div>
//                 <div className={`col-lg-6 pt-4 text-dark-emphasis`}>
//                   {content.label === "Amount" ? `\u20B9 ${content.value}` : ""}
//                 </div>
//               </div>
//             ))}
//         </div>
//       </div>

//       {Data && Data.status === "approved" && (
//         <div className="row px-5">
//           <div className="col-md-10 p-2"></div>
//           <div className="col-md-2 p-2">
//             <Button label="Withdraw" onClick={() => openWithdrawModal()} />
//           </div>

//           {WithdrawModalOpen && (
//             <div className="col-md-12 drawpop p-2">
//               <ConfirmationModal setOpen={setOpen} setConfirm={setConfirm} />
//             </div>
//           )}
//           <CoreLoader show={loading} disableLines />
//         </div>
//       )}
//     </div>
//   );
// };

// export default AdvanceSalaryView;
import { Button, CoreLoader } from "common/components";
import ConfirmationModal from "common/components/ConfirmationModal";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import moment from "moment";

const AdvanceSalaryView = ({ Data, setOpen, fetchapi }) => {
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [cancele_id, setCancel_id] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [WithdrawModalOpen, setWithdrawModalOpen] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const employeeInfo = Data;

  // Function to format dates
  const formatDate = (date) => moment(date).format("L");

  const secLabels = {
    createdAt: "Applied Date",
    amount: "Amount",
    model: "Duration",
    instalment: "Installment",
    advance_salary_type: "Salary Type",
    reason: "Reason",
  };

  const openWithdrawModal = () => {
    setWithdrawModalOpen(true);
    setCancel_id(Data._id);
  };

  const handleStatusChange = () => {
    let dataPayload = {
      id: cancele_id,
      status: "cancelled",
    };
    setLoading(true);
    apiManager
      .advanceSalaryStatus(dataPayload)
      .then((res) => {
        setLoading(false);
        fetchapi && fetchapi();
        setmsgType("success");
        setShowMSG(res?.data?.message || "Details updated successfully");
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "Failed to update");
      });
  };

  const arr = employeeInfo
    ? Object.entries(employeeInfo)
        .filter(([originalLabel]) => secLabels[originalLabel])
        .map(([originalLabel, value]) => ({
          label: secLabels[originalLabel],
          value: originalLabel === "createdAt" ? formatDate(value) : value,
        }))
    : [];

  useEffect(() => {
    if (confirm) {
      handleStatusChange();
      setConfirm(false);
    }
  }, [confirm]);

  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "text-info";
      case "approved":
        return "text-success";
      default:
        return "text-danger";
    }
  };

  return (
    <div className="container">
      {showMSG && (
        <div className={`alert alert-${msgType}`} role="alert">
          {showMSG}
        </div>
      )}
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            {employeeInfo && (
              <>
                <div className="col-lg-12 ps-5">
                  <img
                    src={`${BASE_URL}/${employeeInfo.image}`}
                    alt="Asset"
                    width="200"
                    height="200"
                    className="rounded-circle"
                  />
                </div>
                <div className="col-lg-12">
                  <ul>
                    <li className="row">
                      <span className="col-lg-6 pt-4">Employee ID</span>
                      <span className="col-lg-6 pt-3">{employeeInfo.employeeID}</span>
                    </li>
                    <li className="row">
                      <span className="col-lg-6 pt-4">Employee Name</span>
                      <span className="col-lg-6 pt-3">{employeeInfo.employee_name}</span>
                    </li>
                    <li className="row">
                      <span className="col-lg-6 pt-4">Status</span>
                      <span className={`col-lg-6 pt-3 ${getStatusClass(employeeInfo.status)}`}>
                        {employeeInfo.status}
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-lg-6 pt-3">
          {arr &&
            arr.map((content, index) => (
              <div className="row" key={index}>
                <div className="col-lg-6 pt-4">{content.label}</div>
                <div className={`col-lg-6 pt-4 text-dark-emphasis ${content.label === "Reason" ? "text-break" : ""}`}>
                  {content.label === "Amount" ? `\u20B9 ${content.value}` : content.value === "" ? "NA" : content.value}
                </div>
              </div>
            ))}
        </div>
      </div>

      {Data && Data.status === "approved" && (
        <div className="row px-5">
          <div className="col-md-10 p-2"></div>
          <div className="col-md-2 p-2">
            <Button label="Withdraw" onClick={() => openWithdrawModal()} />
          </div>

          {WithdrawModalOpen && (
            <div className="col-md-12 drawpop p-2">
              <ConfirmationModal setOpen={setOpen} setConfirm={setConfirm} />
            </div>
          )}
          <CoreLoader show={loading} disableLines />
        </div>
      )}
    </div>
  );
};

export default AdvanceSalaryView;
