import React, { useEffect, useState } from "react";
import { SubHeader, Table } from "UI_Components";
import { useLocation, useNavigate } from "react-router-dom";
import apiManager from "networking/baseAPIManager";
import "../../../../assets/style/leaverequest.css";
import "../../../../assets/style/Responsive.css";
import { AlertMessage, Button, CommonModal, CoreLoader, Dropdown } from "common/components";
import SelectPickerInput from "common/components/SelectPicker";
import AddHolidayEdit from "Components/AddHolidaypop/AddHolidayEdit";
import getHolidayList from "utils/holidayServiceUtil";
import { Pagination } from "rsuite";

export const HolidayCalendar = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [editData, setEditData] = useState(null);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 3 }, (_, index) => currentYear + index);
  const [holidayList, setHolidayList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectCountry, setSelectCountry] = useState([]);
  const [selectState, setSelectState] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  const statenamearray = selectState.filter((item) => item?.id == selectedState);
  const statename = statenamearray.map((item) => item?.name);

  const moment = require("moment");

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    const paginatedData = holidayList.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));

    setDisplayedData(paginatedData);
  }, [activePage, holidayList]);

  const columns = [
    { header: "S.No", field: "" },
    {
      header: "Holiday Name",
      field: "holiday_name",
      customHtml: (data) => {
        const holidayName = data?.holiday_name;
        return <span>{holidayName}</span>;
      },
    },
    {
      header: "Day",
      field: "day",
      customHtml: (data) => {
        const holidayDay = data?.day;
        return <span>{holidayDay}</span>;
      },
    },
    {
      header: "Date",
      field: "date",
      customHtml: (data) => {
        const holidayDate = data?.date;
        const formattedDate = holidayDate ? moment(holidayDate).format("DD MMM YYYY") : "N/A";
        return <span>{formattedDate}</span>;
      },
    },
    { header: "Action" },
    // { header: "Actions", field: "actions", btnType: "primary", customHtml },
  ];

  const navigate = useNavigate();

  const handleSaveEdit = (editedData) => {
    const updatedHolidayList = holidayList.map((holiday) =>
      holiday.id === editData.id ? { ...holiday, ...editedData } : holiday
    );
    setHolidayList(updatedHolidayList);
    setOpen(false);
  };

  const searchCalender = async (status = "approved") => {
    setLoading(true);
    try {
      let params = {
        holiday_status: status,
        year: selectedYear,
        country: selectedCountry,
        state: statename[0],
      };

      const response = await getHolidayList(params);
      if (response && response.data && response.data.list) {
        setHolidayList(response.data.list);
      }
      setmsgType("success");
      setShowMSG(response?.data?.message || "Details updated successfully");
    } catch (error) {
      setmsgType("error");
      setShowMSG(error?.response?.data?.message || "Failed to update");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    searchCalender();
  }, [selectedCountry, selectedState, selectedYear]);

  const handleChange = (value, e, id) => {
    if (id && id.toLowerCase() === "year") {
      setSelectedYear(value || currentYear);
      return;
    }

    if (id && id.toLowerCase() === "state" && e) {
      setSelectedState(e.target.value);
      return;
    }
  };

  useEffect(() => {
    setLoading(true);
    apiManager
      .country()
      .then((country) => {
        setLoading(false);
        setSelectCountry(country.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setLoading(true);
      apiManager
        .stateByName(selectedCountry)
        .then((state) => {
          setLoading(false);
          setSelectState(state.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [selectedCountry]);

  let dropdownData = years.map((year) => ({ label: year.toString(), value: year.toString() }));

  return (
    <>
      <div className="Container">
        <SubHeader location={useLocation()} />
        <div className="row p-2 m-0">
          <div className="col-lg-4 col-xl-3 col-md-6 repoartmonth ">
            <Dropdown
              valueKey="name"
              required
              placeholder="Select Country"
              options={selectCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              id="country"
              value={selectedCountry}
            />
          </div>
          <div className="col-lg-4 col-xl-3 col-md-6 repoartmonth">
            <Dropdown
              required
              placeholder="Select State"
              options={selectState}
              onChange={(e) => setSelectedState(e.target.value)}
              id="state"
              value={selectedState}
            />
          </div>
          <div className="col-lg-4 col-xl-3 col-md-6 repoartmonth">
            <SelectPickerInput
              sidelabel="Select Year"
              defaultselect={currentYear}
              data={dropdownData}
              value={selectedYear}
              valueKey={"value"}
              id="year"
              onChange={(value, event, id) => handleChange(value, event, id)}
            />
          </div>
          <div className="col-lg-4 col-xl-2 col-md-3 repoartmonth">
            <Button label="Search" onClick={() => searchCalender("approved")} classes="button-set" />
          </div>
          <div className="col-lg-4 col-xl-12 col-md-6 addholiday">
            <Button
              label="Add Holiday Calendar"
              onClick={() => navigate("/hrtools/attendance/holidayform")}
              classes="button-set"
            />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-lg-12 px-2 pt-0 tableContainer">
            <Table columns={columns} Data={displayedData} LeaveTableHeight="LeaveTableHeight" />
          </div>
          <div className="pt-4 d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={holidayList.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
      </div>
      <CommonModal open={open} onClose={() => setOpen(false)} size="sm" title={"Edit Holiday Calendar"}>
        <AddHolidayEdit onSave={handleSaveEdit} data={editData} />
      </CommonModal>
      <CoreLoader show={loading} disableLines />
    </>
  );
};
