import { AlertMessage, Button, CoreLoader, FloatingInput, Input, Label } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthId, setAuthToken } from "redux/slice";
import { loginSchema } from "utils/formValidationUtils";
import { setGetLocalData } from "utils/localStorageHelper";
import { useFormik } from "formik";
import usePasswordToggle from "common/components/usePasswordToggle";
import Cookies from "js-cookie";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");

  let isLoggedIn = useSelector((state) => state?.data?.token);
  const dispatch = useDispatch();

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();

  const { handleChange, handleSubmit, handleBlur, values, errors, touched, setFieldValue } = useFormik({
    initialValues: { email: "", password: "", rememberMe: false },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true);
      apiManager
        .loginuser(values)
        .then((res) => {
          let { token, id } = res.data;
          setGetLocalData("token", token);
          setGetLocalData("id", id);
          dispatch(setAuthToken(token));
          dispatch(setAuthId(id));

          if (values.rememberMe) {
            Cookies.set("email", values.email, { expires: 7 });
            Cookies.set("password", values.password, { expires: 7 });
          } else {
            Cookies.remove("email");
            Cookies.remove("password");
          }
          navigate("/");
          // navigate("/login");
          setLoading(false);
          setmsgType("success");
          setShowMSG(res?.data?.message || "details updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(err?.response?.data?.message || "failed to update");
          console.log(err?.response?.data?.message);
        });
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }

    const savedEmail = Cookies.get("email");
    const savedPassword = Cookies.get("password");

    if (savedEmail && savedPassword) {
      setFieldValue("email", savedEmail);
      setFieldValue("password", savedPassword);
      setFieldValue("rememberMe", true);
    }
  }, [isLoggedIn, setFieldValue]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <h2 className="mb-5">
          <b>Login To Your Account</b>
        </h2>

        <FloatingInput
          name="email"
          type="email"
          placeholder="Please Enter Your Email"
          label="Email address"
          value={values.email.toLowerCase()}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={touched.email && !errors.email}
          touched={touched.email}
          error={errors.email}
          maxLength={50}
        />
        <FloatingInput
          name="password"
          type={PasswordInputType}
          placeholder="*******"
          label="Password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={touched.password && !errors.password}
          touched={touched.password}
          error={errors.password}
          icons={ToggleIcon}
          maxLength={30}
        />

        <div className="d-flex justify-content-between align-items-center mb-4 gap-3">
          <Input
            className="form-check-input border border-secondary-subtle"
            labelclasses="form-check-label"
            parentclasses="form-check mb-4"
            type="checkbox"
            name="rememberMe"
            onChange={handleChange}
          />
          <Label label="Remember me" parentclasses="mb-4 pe-5" />
          <a href="#!" className="mb-4 nowrap " onClick={() => navigate("/forgotpassword")}>
            Forgot Password ?
          </a>
        </div>

        <div className="d-grid">
          <Button type="submit">Login</Button>
        </div>
        <CoreLoader show={loading} disableLines />
      </form>
    </>
  );
};

export default Login;
