import styles from "./css/input.module.css";

const Label = (props) => {
  const { labelclasses, parentclasses, label, required, uniqueId, htmlFor, For } = props;

  return (
    <div className={parentclasses}>
      <label
        className={` capitalize ${styles.label} ${labelclasses}`}
        htmlFor={`${"input" + uniqueId} ${htmlFor}`}
        for={`${For}`}
      >
        {label} {required && <span className="astricRequired">*</span>}
      </label>
    </div>
  );
};

export default Label;
