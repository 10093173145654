import React, { useEffect, useState } from "react";
import ResetPassword from "./ResetPassword";

const PasswordForgotModal = ({ handleUnmount, openModal }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [emailSent, setEmailSent] = useState("");
  const [message, setMessage] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [resetPasswordIsopenModal, setResetPasswordIsopenModal] = useState(false);
  const handleReset = () => {
    setEmailSent("OTP sent to entered email address");
    setCountDown(15);
    setTimeout(() => {
      setIsDisabled(true);
      setEmailSent();
    }, 15000);
    setMessage("resend OTP after ");

    setInterval(() => {
      setCountDown((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
      });
    }, 1000);
  };

  const handleSubmitOtp = () => {
    setResetPasswordIsopenModal(true);
  };

  return (
    <div style={{ width: "20rem", border: "2px solid black" }}>
      {!resetPasswordIsopenModal ? <h5>Password Forgot Modal</h5> : <h5>Password Reset Modal</h5>}
      <div style={{ margin: "1rem 1rem 0rem 1rem" }}>
        <input type="email" placeholder="enter your email address" />
      </div>
      {!resetPasswordIsopenModal ? (
        <div>
          {" "}
          {isDisabled ? (
            <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleReset()}>
              Resend
            </span>
          ) : message !== "" ? (
            <small style={{ color: "#616362" }}>
              {message}
              <b>{countDown}</b> sec
            </small>
          ) : (
            <button onClick={() => handleReset()}>Send otp </button>
          )}
          {emailSent && (
            <div>
              <span>{emailSent}</span>
              <input type="text" placeholder="enter otp" />
              <button onClick={() => handleSubmitOtp()}>Submit</button>
            </div>
          )}
        </div>
      ) : (
        <ResetPassword />
      )}
    </div>
  );
};

export default PasswordForgotModal;
