// TextArea.js
import React, { useId } from "react";
import styles from "./css/input.module.css";

function Textarea(props) {
  let {
    parentclasses,
    name,
    value,
    onChange,
    textareaclasses,
    rows,
    cols,
    id,
    touched,
    error,
    maxLength,
    isValid,
    onBlur,
    style,
  } = props;
  let uniqueId = useId();
  return (
    <div className={parentclasses}>
      <textarea
        className={`form-controlcustom form-control  ${styles.input} ${textareaclasses} `}
        style={style}
        id={id || uniqueId}
        textareaclasses={textareaclasses}
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
        cols={cols}
        touched={touched}
        error={error}
        maxLength={maxLength}
        isValid={isValid}
        onBlur={onBlur}
      />
    </div>
  );
}

export default Textarea;
