import React, { useId } from "react";
import styles from "./css/input.module.css";
import Label from "./Label";

function Dropdown(props) {
  let {
    label,
    parentclasses,
    id,
    name,
    value,
    valueKey,
    onChange,
    options,
    touched,
    error,
    placeholder,
    selectclasses,
    onFocus,
  } = props;

  let uniqueId = useId();
  const placeholderText = placeholder || "Select";

  return (
    <div className={parentclasses}>
      <select
        className={` form-control form-select ${styles.input} ${selectclasses}`}
        id={id ? id : uniqueId}
        name={name}
        onFocus={onFocus}
        label={label}
        value={value}
        placeholder={placeholderText}
        onChange={(e) => onChange(e)}
      >
        <option value="" selected disabled>
          {placeholderText}
        </option>
        {options &&
          options.map((option) => {
            return (
              <option key={option.key || option.id} value={valueKey ? option[valueKey] : option.value || option.id}>
                {option.value || option.name || option}
              </option>
            );
          })}
      </select>
      {touched && error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

export default Dropdown;
