import React from "react";
import { useState } from "react";
const UserRegister = () => {
  const [registeredEmployee, setRegisteredEmployee] = useState({});
  const [inputFields, setInputFields] = useState({
    email: "",
    pass: "",
    cnfPass: "",
  });
  const [token, setToken] = useState();
  const isValidInputfield = (email) => {
    const emailpattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailpattern.test(email);
  };
  const [error, setError] = useState({
    emailError: "",
    pasMatchError: "",
  });

  const handleOnchange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setInputFields({ ...inputFields, email: value });
    } else if (name === "password") {
      if (isValidInputfield(inputFields.email)) {
        setInputFields({ ...inputFields, pass: value });
        setError({});
      } else {
        setError({ emailError: "Invalid Email" });
      }
    } else if (name == "confirmPassword") {
      setInputFields({ ...inputFields, cnfPass: value });
      setError({});
    } else {
      console.log("No inputs Value");
    }
  };

  const handleRegistration = () => {
    if (inputFields.pass === inputFields.cnfPass) {
      setRegisteredEmployee({
        email: inputFields.email,
        password: inputFields.pass,
      });
    } else {
      setError({ pasMatchError: "Password do not match" });
      console.log("invalid credential");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h5>Registeration</h5>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <input
            type="email"
            placeholder="Enter Email"
            value={inputFields.email}
            name="email"
            onChange={handleOnchange}
          />

          {error.emailError && <span>{error.emailError}</span>}
        </div>
        <div>
          <input
            type="password"
            placeholder="Enter Password"
            value={inputFields.pass}
            name="password"
            onChange={handleOnchange}
          />
        </div>

        <div>
          <input
            type="password"
            placeholder="Confirm Password"
            value={inputFields.cnfPass}
            name="confirmPassword"
            onChange={handleOnchange}
          />
          {error.pasMatchError && <span>{error.pasMatchError}</span>}
        </div>
        <div>
          <button disabled={!(inputFields.email && inputFields.pass)} onClick={() => handleRegistration()}>
            SignUp
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserRegister;
