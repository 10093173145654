import React, { useState } from "react";
import { Link } from "react-router-dom";

import apiManager from "../../../networking/baseAPIManager";

const UserLogin = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState();
  const [inputValue, setInputValue] = useState({
    username: "",
    password: "",
  });

  const handleOnchange = (event) => {
    const { name, value } = event.target;
    if (name === "username") {
      setInputValue({ username: value });
    } else if (name === "password") {
      setInputValue({ ...inputValue, password: value });
    } else {
      console.log("no credential");
    }
  };

  const handleLogin = () => {
    const { username, password } = inputValue;
    if (username !== "" && password !== "") {
      apiManager
        .loginuser(inputValue)
        .then((res) => {
          setLoggedInUser(inputValue);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleOutsideClick = () => {
    if (openModal) {
      setOpenModal(false);
    }
  };
  window.addEventListener("click", handleOutsideClick);

  return (
    <div
      style={{
        textAlign: "center",
        margin: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "1rem",
          gap: "1rem",
        }}
      >
        <h5>
          user login <hr />
        </h5>
        <div>
          <input
            onChange={handleOnchange}
            type="email"
            placeholder="username"
            value={inputValue.username}
            name="username"
            required
          />
        </div>

        <div>
          <input
            onChange={handleOnchange}
            type="password"
            placeholder="password"
            value={inputValue.password}
            name="password"
          />
          <br />{" "}
          <Link to="/attendance-password-forgot">
            {" "}
            <span style={{ color: "blue" }}>Forgot password ?</span>{" "}
          </Link>
        </div>

        <div>
          <button onClick={() => handleLogin()}>sign in </button>
        </div>
        {loggedInUser && (
          <div style={{ padding: "1rem" }}>
            Logged in user detail : <h5>{loggedInUser.username}</h5>
            <p>{loggedInUser.password}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserLogin;
