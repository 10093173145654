import { AlertMessage, Button, CoreLoader, DownloadIcon, Dropdown, Input, Label } from "common/components";
import { useFormik } from "formik";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userDefaultImage from "../../../../assets/images/UserDefultImage.png";

import { OnboardingFormSchema } from "utils/formValidationUtils";

function Candidatedetails() {
  const paramsData = useParams();
  let baseURL = process.env.REACT_APP_BASE_URL;
  const imageInputRef = useRef();
  const navigate = useNavigate();

  let empid = paramsData?.empid;

  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [loading, setLoading] = useState(false);
  const [empdata, setEmpdata] = useState({});
  const [relationshipData, setRelationshipData] = useState([]);
  const [nextPage, setnextPage] = useState(false);

  const getRelationshipData = () => {
    setLoading(true);

    apiManager
      .getDropdownTypes("familyRelations")
      .then((response) => {
        setLoading(false);
        setRelationshipData(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getCandidateDetails = () => {
    setLoading(true);
    apiManager
      .candidateDetails(empid)
      .then((candidate) => {
        setLoading(false);

        setEmpdata(candidate?.data?.data);

        setnextPage(!!candidate?.data?.nextPage);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (empid) getCandidateDetails();
  }, [empid]);

  useEffect(() => {
    getRelationshipData();
  }, []);

  const forminitialValue = {
    profileImage: empdata?.image || "",
    firstName: empdata?.firstname || "",
    middleName: empdata?.middlename || "",
    lastName: empdata?.lastname || "",
    dobDocument: empdata?.documentDob || "",
    dobActual: empdata?.originalDob || "",
    gender: empdata?.gender || "",
    email: empdata?.email || "",
    mobileNum: empdata?.mobile_number || "",
    sec_mobileNum: empdata?.emergency_number || "",
    aadharcard: empdata?.aadharcard_no?.toString() || "",
    pancard: empdata?.pancard_no || "",
    pancardfile: empdata?.pan_image || "",
    aadharcardfile: empdata?.aadhar_image || "",
    family_member_first_name: empdata?.family_member_first_name || "",
    family_member_last_name: empdata?.family_member_last_name || "",
    relationship: empdata?.relationship || "",
    family_member_dob: empdata?.family_member_dob || "",
    family_member_phone: empdata?.family_member_phone || "",
    family_member_email: empdata?.family_member_email || "",
  };

  const { handleChange, handleBlur, handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: forminitialValue,
    validationSchema: OnboardingFormSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
    enableReinitialize: true,
  });
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   // Remove whitespace
  //   const trimmedValue = value.replace(/\s+/g, "");
  //   setFieldValue(name, trimmedValue);
  // };
  let userImage = values.profileImage ? `${baseURL}/${values.profileImage}` : userDefaultImage;

  if (typeof values.profileImage == "object") {
    userImage = URL.createObjectURL(values.profileImage);
  }

  let handleUpdadate = (formData) => {
    setLoading(true);
    apiManager
      .updateEmployee(formData)
      .then((res) => {
        setLoading(false);
        setShowMSG("Details updated");
        setmsgType("success");
        navigate(`/hrtools/employee-onboarding/addressdetails/${empid}`);
      })
      .catch((err) => {
        setLoading(false);
        setShowMSG(err?.response?.data?.message || "Failed to Update Details");
        setmsgType("error");
        console.log(err);
      });
  };

  const handleSave = (data) => {
    let formData = new FormData();
    if (empid) {
      formData.append("empid", empid);
    }

    formData.append("firstname", data.firstName);
    formData.append("middlename", data.middleName);
    formData.append("lastname", data.lastName);
    formData.append("documentDob", data.dobDocument);
    formData.append("originalDob", data.dobActual);
    formData.append("gender", data.gender);
    formData.append("email", data?.email?.toLowerCase());
    formData.append("mobile_number", data.mobileNum);
    formData.append("emergency_number", data.sec_mobileNum);
    formData.append("aadharcard_no", data.aadharcard);
    formData.append("pancard_no", data.pancard);
    formData.append("pan_image", data.pancardfile);
    formData.append("aadhar_image", data.aadharcardfile);
    formData.append("image", data.profileImage);
    formData.append("family_member_first_name", data.family_member_first_name);
    formData.append("family_member_last_name", data.family_member_last_name);
    formData.append("relationship", data.relationship);
    formData.append("family_member_dob", data.family_member_dob);
    formData.append("family_member_phone", data.family_member_phone);
    formData.append("family_member_email", data.family_member_email);

    if (empid) {
      handleUpdadate(formData);
      return;
    }
    setLoading(true);

    apiManager
      .addEmployee(formData)
      .then((res) => {
        setLoading(false);
        let empId = res?.data?.data?._id;
        setShowMSG("Details updated");
        setmsgType("success");
        navigate(`/hrtools/employee-onboarding/addressdetails/${empId}`);
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.response?.data?.errors || "Failed to Update Details");
      });
  };

  const renderFileInput = (name) => {
    if (values[name] && typeof values[name] == "string") {
      const downloadAttachment = async () => {
        let BASE_URL = process.env.REACT_APP_BASE_URL;
        const attachmentData = `${BASE_URL}/${values[name]}`;

        if (!attachmentData) {
          console.error("No attachment data found.");
          return;
        }

        try {
          const response = await fetch(attachmentData);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", values[name]);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } catch (error) {
          console.error("Error fetching or triggering download:", error);
        }
      };
      return (
        <div className="w-50 d-flex gap-3 align-items-center my-auto">
          <Button onClick={() => downloadAttachment()} label="Download" type="button" />
          {empdata.status == "pending" ? (
            <span
              className="material-symbols-outlined pointer"
              onClick={() => {
                setFieldValue([name], undefined);
              }}
            >
              edit
            </span>
          ) : (
            ""
          )}
        </div>
      );
    }

    return (
      <Input
        type="file"
        label="Upload Result"
        accept="image/*, .pdf"
        name={name}
        onChange={(e) => {
          setFieldValue(name, e.target.files[0]);
        }}
        onBlur={handleBlur}
        isValid={touched[name] && !errors[name]}
        touched={touched[name]}
        error={errors[name]}
      />
    );
  };
  const handleFirstletterChange = (event) => {
    const { name, value } = event.target;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    handleChange({ target: { name, value: capitalizedValue } });
  };

  return (
    <>
      <div className="text-center subheaderbackgroundoffWhite p-2 d-flex align-items-center justify-content-center">
        <h2>Candidate Details </h2>
      </div>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <form onSubmit={handleSubmit}>
        <div className="p-4">
          <div className="row py-3">
            <div className="col-xl-4 col-lg-6">
              <div className="d-flex justify-content-center pb-2">
                {
                  <div style={{}}>
                    <img className="userImage " src={userImage} width="100px" height="100px" />
                    {(touched?.profileImage || errors?.profileImage) && (
                      <p className="text-danger pt-1 d-flex justify-content-center">{errors?.profileImage}</p>
                    )}
                  </div>
                }

                <input
                  accept="image/*"
                  type="file"
                  className="d-none"
                  ref={imageInputRef}
                  onChange={(e) => {
                    setFieldValue("profileImage", e.target.files[0]);
                  }}
                />
              </div>
              <div className="d-flex justify-content-center pb-2">
                <Button
                  type="button"
                  label="Upload Photo"
                  onClick={() => {
                    imageInputRef.current.click();
                  }}

                  // className="button ms-5 ps-4 mt-2"
                />
              </div>
            </div>
            <div className="col-xl-8 col-lg-6">
              <div className="form-outline mb-4 ms-0">
                <Label label="First Name" required labelclasses="pb-1" />
                <Input
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleFirstletterChange}
                  onBlur={handleBlur}
                  maxLength={15}
                  isValid={touched.firstName && !errors.firstName}
                  touched={touched.firstName}
                  error={errors.firstName}
                />
              </div>
              <div className="form-outline mb-4 ms-0">
                <Label label="Middle Name" labelclasses="pb-1" />
                <Input
                  type="text"
                  name="middleName"
                  value={values.middleName}
                  onChange={handleFirstletterChange}
                  onBlur={handleBlur}
                  maxLength={15}
                  touched={touched.middleName}
                  // isValid={touched.middleName && !errors.middleName}
                  error={errors.middleName}
                />
              </div>
              <div className="form-outline mb-4 ms-0">
                <Label label="Last Name" labelclasses="pb-1" />
                <Input
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleFirstletterChange}
                  onBlur={handleBlur}
                  maxLength={15}
                  isValid={touched.lastName && !errors.lastName}
                  touched={touched.lastName}
                  error={errors.lastName}
                />
              </div>
            </div>
          </div>

          <div className="mb-4 row">
            <div className="form-outline mb-4 ms-0 col-lg-4">
              <Label labelclasses="pb-1" label="Documental DOB" required />
              <Input
                type="date"
                name="dobDocument"
                value={values.dobDocument}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.dobDocument && !errors.dobDocument}
                touched={touched.dobDocument}
                error={errors.dobDocument}
                min="1950-01-01"
                max="2999-12-31"
              />
            </div>
            <div className="form-outline mb-4 ms-0 col-lg-4">
              <Label labelclasses="pb-1" label="Original DOB" required />
              <Input
                type="date"
                name="dobActual"
                value={values.dobActual}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.dobActual && !errors.dobActual}
                touched={touched.dobActual}
                error={errors.dobActual}
                min="1950-01-01"
                max="2999-12-31"
              />
            </div>
            <div className="col-lg-4">
              <Label labelclasses="pb-1" label="Gender" htmlFor="" required />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input "
                  type="radio"
                  value="male"
                  name="gender"
                  id="male"
                  checked={values.gender === "male"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.gender && !errors.gender}
                />
                <label className="form-check-label" for="male">
                  Male
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  value="female"
                  name="gender"
                  id="female"
                  checked={values.gender === "female"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.gender && !errors.gender}
                />
                <label className="form-check-label" for="female">
                  Female
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  value="other"
                  name="gender"
                  id="other"
                  checked={values.gender === "other"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.gender && !errors.gender}
                />
                <label className="form-check-label" for="other">
                  Other
                </label>
              </div>
              {touched.gender && errors.gender && <div className="error_message">{errors.gender}</div>}
            </div>
            <div className="form-outline mb-4 ms-0 col-lg-4">
              <Label labelclasses="pb-1" label="Personal Email" required />
              <Input
                name="email"
                value={values.email.toLowerCase()}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={45}
                isValid={touched.email && !errors.email}
                touched={touched.email}
                error={errors.email}
              />
            </div>
            <div className="form-outline mb-4 ms-0 col-lg-4">
              <Label labelclasses="pb-1" label="Mobile No." required />
              <Input
                type="text"
                name="mobileNum"
                value={values.mobileNum}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.mobileNum && !errors.mobileNum}
                maxLength={10}
                touched={touched.mobileNum}
                error={errors.mobileNum}
              />
            </div>
            <div className="form-outline mb-4 ms-0 col-lg-4">
              <Label labelclasses="pb-1" label="Emergency Contact" required />
              <Input
                type="text"
                name="sec_mobileNum"
                value={values.sec_mobileNum}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.sec_mobileNum && !errors.sec_mobileNum}
                maxLength={10}
                touched={touched.sec_mobileNum}
                error={errors.sec_mobileNum}
              />
            </div>
          </div>
          <div className=" row">
            <div className="form-outline col-lg-6 mb-4 ms-0">
              <Label labelclasses="pb-1" label="Aadhaar Number" required />
              <Input
                type="text"
                name="aadharcard"
                value={values.aadharcard}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.aadharcard && !errors.aadharcard}
                maxLength={12}
                touched={touched.aadharcard}
                error={errors.aadharcard}
              />
              {/* <Input
                type="text"
                name="aadharcard"
                value={values.aadharcard}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  value = value.replace(/(.{4})(.{4})(.{4})/, "$1-$2-$3"); // Add hyphens after every 4th digit
                  handleChange({
                    target: {
                      name: "aadharcard",
                      value: value,
                    },
                  });
                }}
                onBlur={handleBlur}
                isValid={touched.aadharcard && !errors.aadharcard}
                maxLength={14} // Adjusting to account for hyphens
                touched={touched.aadharcard}
                error={errors.aadharcard}
              /> */}
            </div>
            <div className="form-outline col-lg-6 mt-4 mb-4 ">{renderFileInput("aadharcardfile")}</div>
          </div>
          <div className=" row">
            <div className="form-outline col-lg-6 mb-4 ms-0">
              <Label labelclasses="pb-1" label="Pan Number" required />
              <Input
                type="text"
                name="pancard"
                value={values.pancard.toUpperCase()}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.pancard && !errors.pancard}
                maxLength={10}
                touched={touched.pancard}
                error={errors.pancard}
              />
            </div>
            <div className="form-outline col-lg-6 mb-4 mt-4">{renderFileInput("pancardfile")}</div>
          </div>

          <div>
            <div className="mb-3">
              <h5>Family Information</h5>
            </div>
            <div className="mb-4 row">
              <div className="form-outline col-lg-6 mb-4 ms-0">
                <Label labelclasses="pb-1" label="First Name" required />
                <Input
                  type="text"
                  name="family_member_first_name"
                  value={values.family_member_first_name}
                  onChange={handleFirstletterChange}
                  onBlur={handleBlur}
                  isValid={touched.family_member_first_name && !errors.family_member_first_name}
                  maxLength={15}
                  touched={touched.family_member_first_name}
                  error={errors.family_member_first_name}
                />
              </div>
              <div className="form-outline col-lg-6 mb-4 ms-0">
                <Label labelclasses="pb-1" label="Last Name" />
                <Input
                  type="text"
                  name="family_member_last_name"
                  value={values.family_member_last_name}
                  onChange={handleFirstletterChange}
                  onBlur={handleBlur}
                  isValid={touched.family_member_last_name && !errors.family_member_last_name}
                  maxLength={15}
                  touched={touched.family_member_last_name}
                  error={errors.family_member_last_name}
                />
              </div>
              <div className="form-outline col-lg-6 mb-4 ms-0">
                <Label labelclasses="pb-1" label="Relationship" required />
                <Dropdown
                  type="text"
                  name="relationship"
                  options={relationshipData}
                  value={values.relationship}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.relationship && !errors.relationship}
                  touched={touched.relationship}
                  error={errors.relationship}
                />
              </div>
              <div className="form-outline col-lg-6 mb-4 ms-0">
                <Label labelclasses="pb-1" label="Date Of Birth" required />
                <Input
                  type="date"
                  name="family_member_dob"
                  value={values.family_member_dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.family_member_dob && !errors.family_member_dob}
                  touched={touched.family_member_dob}
                  error={errors.family_member_dob}
                  min="1900-01-01"
                  max="2999-12-31"
                />
              </div>
              <div className="form-outline col-lg-6 mb-4 ms-0">
                <Label labelclasses="pb-1" label="Phone" required />
                <Input
                  type="tel"
                  name="family_member_phone"
                  value={values.family_member_phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={10}
                  isValid={touched.family_member_phone && !errors.family_member_phone}
                  touched={touched.family_member_phone}
                  error={errors.family_member_phone}
                />
              </div>
              <div className="form-outline col-lg-6 mb-4 ms-0">
                <Label labelclasses="pb-1" label="Email" />
                <Input
                  type="text"
                  name="family_member_email"
                  value={values.family_member_email.toLowerCase()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={35}
                  isValid={touched.family_member_email && !errors.family_member_email}
                  touched={touched.family_member_email}
                  error={errors.family_member_email}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
          <Button type="submit" label="Save & Continue" classes="btn-outline-dark bg-light text-primary" />
          {nextPage ? (
            <Button
              type="button"
              label="Next"
              onClick={() => navigate(`/hrtools/employee-onboarding/addressdetails/${empid}`)}
            />
          ) : (
            ""
          )}
        </div>
      </form>
      <CoreLoader show={loading} />
    </>
  );
}

export default Candidatedetails;
