import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AlertMessage({ type = "info", title = "", message = "", show, setShow }) {
  useEffect(() => {
    if (show && setShow) {
      const toastOptions = {
        onClose: () => setShow(false),
        autoClose: 5000,
        position: "top-right",
        closeButton: true,
      };

      switch (type) {
        case "success":
          toast.success(show || message, toastOptions);
          break;
        case "error":
          toast.error(show || message, toastOptions);
          break;
        case "warning":
          toast.warn(show || message, toastOptions);
          break;
        case "info":
        default:
          toast.info(show || message, toastOptions);
          break;
      }
    }
  }, [show, setShow, type, title, message]);

  return (
    <>
      <ToastContainer />
    </>
  );
}

export default AlertMessage;
