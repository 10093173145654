import { SubHeader } from "UI_Components";
import React, { useEffect, useState } from "react";
import "../../../../src/assets/style/managefund.css";
import { useLocation, useNavigate } from "react-router-dom";
import candidate from "../../../assets/images/candidate.gif";
import recruitment from "../../../assets/images/recruitment.gif";
import calender from "../../../assets/images/calendar.gif";
import approved from "../../../assets/images/approved.gif";
import Charts from "common/components/Chart";
import EmployeeManageFund from "Components/ManageFund/EmployeeManageFund";
import apiManager from "networking/baseAPIManager";
import { CoreLoader } from "common/components";

const ManageFund = () => {
  const [titleData, setTitleData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const donutoption = {
    series: [
      titleData?.payrollSummarydata?.payment ?? 1,
      titleData?.payrollSummarydata?.pending ?? 5,
      titleData?.payrollSummarydata?.Paid ?? 6,
    ],

    chart: {
      type: "donut",
      width: "50%",
    },
    labels: ["Payment", "Pending", "Paid"],

    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            // total: {
            //   showAlways: true,
            //   show: true,
            // },
          },
        },
      },
    },
    legend: {
      fontSize: "16px",
      fontFamily: "Arial, sans-serif",
      fontWeight: "600",
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 80,
        options: {
          chart: {},
          legend: {
            // position: "bottom",
          },
        },
      },
    ],
  };

  const option = {
    series: titleData?.costSummer?.series || [{ name: "Net Pay", data: ["2", "5", "7", "6", "9"] }],
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Payroll Cost Summer",
      align: "left",
      style: {
        fontWeight: "600",
        fontSize: "20px",
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "35%",
        endingShape: "rounded",
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 1,
    //     inverseColors: false,
    //     opacity: 1,
    //   },
    // },
    // yaxis: {
    //   title: {
    //     text: "$ (thousands)",
    //   },
    // },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "₹ " + val + " k";
        },
      },
    },
  };

  const cardData = [
    {
      id: 1,
      imgIcon: candidate,
      title: titleData?.Count ? `(${titleData?.Count})` : "",
      subTitle: "Total employees",
      path: "",
    },
    {
      id: 2,
      imgIcon: recruitment,
      title: titleData?.totalWorkingDays ? `(${titleData?.totalWorkingDays})` : "",
      subTitle: "Total Working Days",
      path: "",
    },
    {
      id: 3,
      imgIcon: approved,
      title: titleData?.totalnetSalary ? `(${titleData?.totalnetSalary})` : "",
      subTitle: "Net Salary",
      path: "",
    },
    {
      id: 4,
      imgIcon: calender,
      title: "83%",
      subTitle: "Deduction",
      path: "/hrtools/attendance-tracking/leave-request#Approval-Request",
    },
  ];

  useEffect(() => {
    datafetch();
  }, []);

  const datafetch = () => {
    setLoading(true);
    apiManager

      .Managefundtotalemployee()
      .then((response) => {
        setLoading(false);

        setTitleData(response?.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div>
      <SubHeader location={useLocation()} />
      <div className="container-fluid">
        <div className="row g-3  p-4 ">
          {cardData &&
            cardData.map((contentData, index) => (
              <div
                key={index}
                className="col-lg-6 col-xxl-3 "
                // onClick={() => navigate(contentData.path, { state: contentData.carryData })}
              >
                <div className="border  rounded-3 mb-3 p-3 ">
                  <div className="row align-items-center  ">
                    <div className="col-lg-4 p-3">
                      <img src={contentData.imgIcon} height="71rem" width="74rem" alt="" />
                    </div>
                    <div className="col-lg-8  p-3 text-center ">
                      <div
                        className=""
                        style={{
                          color: "#1C3F98",
                          fontSize: "1.4rem",
                          fontWeight: "500",
                        }}
                      >
                        {contentData.title}
                      </div>
                      <div className=" ">{contentData.subTitle}</div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="row g-3  ">
          <div className=" col-lg-8 ">
            <div className="border rounded-2  p-3">
              <div className="h-[300px]  ">
                {titleData?.costSummer?.series.length > 0 && <Charts option={option} type="bar" height={300} />}
              </div>
            </div>
          </div>
          <div className=" col-lg-4  border rounded-2 ">
            <h5 className="fw-bold mt-4">Payrol Summery</h5>
            <div className="flex justify-center item-center ">
              {titleData?.payrollSummarydata && <Charts option={donutoption} type="donut" height={270} />}
            </div>
          </div>
        </div>
      </div>

      <EmployeeManageFund />
      <CoreLoader show={loading} />
    </div>
  );
};

export default ManageFund;
