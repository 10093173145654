import React from "react";
import NoData from "../../assets/images/No data-pana.svg";

const NoDataFound = () => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center ">
        <div className="text-center">
          <img
            src={NoData} // You can replace this with a relevant image
            alt="No Data"
            width="250px"
            className="mt-5 pt-5"
          />
          <h3>No Record Found</h3>
          <p className="text-muted">We couldn't find any data to display at this moment.</p>
        </div>
      </div>
    </>
  );
};

export default NoDataFound;
