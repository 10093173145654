import { Button, CommonModal, CoreLoader, Dropdown, Input } from "common/components";

import VerifyAssetInfo from "Components/AssetManagement/VerifyAssetInfo";
import { SearchFilter } from "Components/AttendaceReport";

import apiManager from "networking/baseAPIManager";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Pagination } from "rsuite";
import { SubHeader, Table } from "UI_Components";

const PendingAssetVerification = () => {
  const [pendingData, setPendingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();
  const [getAssetTypeData, setGetAssetTypeData] = useState([]);
  const [getSupplierTypeData, setGetSupplierTypeData] = useState([]);
  const [selectSupplierData, setSelectSupplierData] = useState([]);
  const [selectAssetData, setSelectAssetData] = useState([]);
  const [searchString, setSearchString] = useState();
  const [filteredApprovalData, setFilteredApprovalData] = useState(pendingData);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = filteredApprovalData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, filteredApprovalData]);
  const customHtml = (data) => {
    return <Button onClick={() => handleRowClick(data)} label="View" />;
  };
  const columns = [
    { header: "S.No", field: "" },
    { header: "Asset Name", field: "assetname" },
    { header: "Asset ID", field: "assetId" },
    { header: "Manufacturer", field: "manufacturer" },
    { header: "Supplier", field: "supplier" },
    { header: "Purchase Date", field: "purchase_date" },
    { header: "Warranty End", field: "warranty" },
    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        let statusColor;
        let statusIndicator;

        if (data.status.toLowerCase() === "repair") {
          statusColor = "red";
          statusIndicator = "●";
        } else {
          statusColor = "blue";
          statusIndicator = "●";
        }

        return (
          <h6 className="pt-2">
            <span style={{ color: statusColor }}>{statusIndicator}</span>
            <span className="ps-2  text-black">{data.status}</span>
          </h6>
        );
      },
    },
    { header: "Action", field: "action", btnType: "primary", customHtml },
  ];

  const getAssetType = () => {
    apiManager
      .getDropdownTypes("assetType")
      .then((response) => {
        setGetAssetTypeData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const getSupplier = () => {
    apiManager
      .getDropdownTypes("supplier")
      .then((response) => {
        setGetSupplierTypeData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const pendingDataList = () => {
    setLoading(true);
    apiManager
      .assetList()
      .then((response) => {
        setPendingData(response?.data?.Asset);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    pendingDataList();
    getAssetType();
    getSupplier();
  }, []);

  const handleRowClick = (asset) => {
    setIsOpen(true);
    setSelectedAsset(asset);
  };
  useEffect(() => {
    let result = pendingData;
    if (searchString) {
      result = result.filter((rowObj) => {
        return (
          rowObj.warranty.toLowerCase().includes(searchString.toLowerCase()) ||
          rowObj.purchase_date.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    }

    if (typeof selectAssetData === "string" && selectAssetData.trim() !== "All") {
      result = result.filter(
        (rowObj) => rowObj.assetname.trim().toLowerCase() === selectAssetData.trim().toLowerCase()
      );
    }
    if (typeof selectSupplierData === "string" && selectSupplierData !== "All") {
      result = result.filter(
        (rowObj) => rowObj.supplier.trim().toLowerCase() === selectSupplierData.trim().toLowerCase()
      );
    }
    setFilteredApprovalData(result);
  }, [pendingData, searchString, selectAssetData, selectSupplierData]);
  return (
    <div>
      <SubHeader location={useLocation()} />
      <div className="container-fluid">
        <div className="row m-0 p-0">
          <div className="col-lg-4 mt-4">
            <SearchFilter data={searchString} setSearchString={setSearchString} placeholder={"Search Date "} />
          </div>
          <div className="col-lg-3 mt-4">
            <Dropdown
              name="selectAssetData"
              options={getAssetTypeData}
              onChange={(e) => setSelectAssetData(e?.target?.value)}
              value={selectAssetData}
              placeholder={"Select Asset Type"}
            />
          </div>
          <div className="col-lg-3 mt-4">
            <Dropdown
              name="selectSupplierData"
              options={getSupplierTypeData}
              onChange={(e) => setSelectSupplierData(e?.target?.value)}
              value={selectSupplierData}
              placeholder={"Select Supplier"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 px-2 pt-3 tableContainer">
            <Table columns={columns} Data={displayedData} className="pointer" />
          </div>
          <div className="pt-4 d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={filteredApprovalData.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
      </div>
      <CommonModal open={isOpen} onClose={() => setIsOpen(false)} size="md" title="Verify Asset Info">
        <VerifyAssetInfo Data={selectedAsset} isOpen={isOpen} setIsOpen={setIsOpen} />
      </CommonModal>
      <CoreLoader show={loading} disableLines />
    </div>
  );
};

export default PendingAssetVerification;
