import { SubHeader } from "UI_Components";
import { AlertMessage, Button, Dropdown, Input, Label, MultiSelect } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { date } from "yup";

export default function CreateDocument() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [lettersOpctions, setlettersOpctions] = useState("");
  const [employeeDocuments, setEmployeeDocuments] = useState({});
  const [inputForm, setInputForm] = useState({
    document: "",
    remark: "",
    id: "",
    empid: "",
  });
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  var moment = require("moment");

  var JoiningDate = moment(employeeDocuments.createdAt, "YYYY-MM-DD");
  const fullName =
    employeeDocuments?.firstname +
    " " +
    (employeeDocuments?.middlename ? employeeDocuments?.middlename + " " : "") +
    employeeDocuments?.lastname;

  useEffect(() => {
    if (state) {
      setEmployeeDocuments(state);
    }
  }, [state]);

  useEffect(() => {
    const fetchEmployeeDocuments = () => {
      setLoading(true);
      apiManager
        .getDropdownTypes("documents")
        .then((response) => {
          // setmsgType("success");
          // setShowMSG(response?.data?.message || "Document created successfully");
          setlettersOpctions(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching leave type options:", error);
          setLoading(false);
          setmsgType("error");
          setShowMSG(error?.response?.data?.message || "failed to add asset");
        });
    };
    fetchEmployeeDocuments();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const documents = {
      document: inputForm?.document,
      remark: inputForm?.remark,
      empid: employeeDocuments._id,
    };
    apiManager
      .employeedocs(documents)
      .then((response) => {
        setLoading(false);
        setmsgType("success");
        setShowMSG(response?.data?.message || "asset added successfully");
        navigate("/hrtools/generate-documents/employee-letter", { state: { data: response?.data } });
      })
      .catch((error) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(error?.response?.data?.message || "failed to add asset");
        console.error("Error fetching employee documents:", error);
      });
  };
  return (
    <div>
      <SubHeader location={useLocation()} />
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <div className="col-lg-12 px-5 ">
        {/* <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} /> */}
        <div className="col-lg-12 pt-4 form-main ">
          <div className="gap-3 text-center subheaderbackgroundoffWhite p-2 d-flex align-items-center justify-content-center">
            <h4 className="m-0">Employee Details</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="container pb-5">
              <div className="form-container">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-md-5 mt-4 ms-2">
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Select Document" />
                      <Dropdown
                        value={inputForm.document}
                        onChange={(e) => {
                          setInputForm({ ...inputForm, document: e.target.value });
                        }}
                        options={lettersOpctions}
                        placeholder="Select Document"
                      />
                    </div>

                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Email ID" />
                      <Input name="Email ID" value={employeeDocuments.email} disabled />
                    </div>

                    {/* <Input name="Email ID" type="hidden" value={employeeDocuments.empId} /> */}

                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Department" />
                      <Input name="Department" disabled value={employeeDocuments?.department} />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Joining Date" />
                      <Input type="date" disabled name="Joining Date" value={JoiningDate.format("YYYY-MM-DD")} />
                    </div>
                    {/* <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Authorised Signatiry" />
                      <Input name=" Authorized Signatiry" value={""} />
                    </div> */}
                  </div>
                  <div className="col-md-5  mt-4 ms-2">
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Employee Name" />
                      <Input name="Employee Name" value={fullName} disabled />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Contact No" />
                      <Input name="Contact No" value={employeeDocuments?.mobile_number} disabled />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Designation" />
                      <Input
                        name="Designation"
                        value={employeeDocuments?.designation ? employeeDocuments?.designation : ""}
                        disabled
                      />
                    </div>
                    {/* <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Reporting Manager" />
                      <Input name="Reporting Manager" value={""} />
                    </div> */}
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Date of Ending" />
                      <Input name="Designation" value={""} />
                    </div>
                  </div>
                  <div className="col-md-10 ms-2 me-2">
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Remark" />
                      <Input
                        name="Remark"
                        value={inputForm.remark}
                        onChange={(e) => setInputForm({ ...inputForm, remark: e.target.value })}
                        maxLength={200}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-lg-12 d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
              <Button className="btn btn-primary sub-btn" variant="primary" type="submit" label="Submit" />

              <Button
                className=" btn btn-primary can-btn"
                variant="primary"
                label="Back"
                onClick={() => navigate("/hrtools/generate-documents")}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
