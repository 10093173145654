import { createSlice } from "@reduxjs/toolkit";
import { setGetLocalData } from "utils/localStorageHelper";

let sessionToken = setGetLocalData("token");
let sessionId = setGetLocalData("id");

export const initialState = {
  data: "",
  token: sessionToken || "",
  id: sessionId || "",
  userResources: {},
  refresh: false,
};

export const localDataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setAuthId: (state, action) => {
      state.id = action.payload;
    },
    setUserResources: (state, action) => {
      state.userResources = action.payload;
    },

    triggerRefresh: (state) => {
      state.refresh = !state.refresh;
    },
  },
});

export const { setAuthToken, setUserResources, triggerRefresh, setAuthId } = localDataSlice.actions;

export default localDataSlice.reducer;
