import LeaveView from "Components/LeaveRequest/LeaveView";
import { Table } from "UI_Components";
import { AlertMessage, Button, CommonModal, CoreLoader } from "common/components";
import ConfirmationModal from "common/components/ConfirmationModal";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { Pagination } from "rsuite";

export default function ResignationaPending() {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [view, setView] = useState(false);
  const [offboardingPendingData, setOffboardingPendingData] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [cancelledId, setcancelled] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = offboardingPendingData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, offboardingPendingData]);

  const getOffboardingPendingData = () => {
    setLoading(true);
    apiManager
      .OffboardingPendingList("pending")
      .then((response) => {
        setOffboardingPendingData(response?.data?.List || []);
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOffboardingPendingData();
  }, []);
  const handleOpenDelete = () => {
    setOpen(true);
    setDeleteModal(true);
  };
  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };
  const customHtml1 = (data) => {
    return <Button onClick={() => handleDeleteModal(data)} label="Delete" />;
  };

  const customHtml2 = (data) => (
    <div className="d-flex gap-3">
      {customHtml(data)}
      {customHtml1(data)}
    </div>
  );
  var moment = require("moment");
  const formatDate = (date) => moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("LL");
  const columns = [
    { header: "S.No", field: "" },
    { header: "Employee Name", field: "employeeName" },
    { header: "Resignation Type", field: "resignation_type" },
    { header: "Notice Date", field: "notice_date", customHtml: (data) => formatDate(data.notice_date) },
    { header: "Resignation Date", field: "resignation_date", customHtml: (data) => formatDate(data.resignation_date) },
    // { header: "Reason", field: "reason" },
    { header: "", field: "actions", btnType: "primary", customHtml: customHtml2 },
  ];
  const handleItemClick = (data) => {
    setModalContent(data);
    setOpen(true);
    setDeleteModal(false);
    setView(true);
  };

  const handleDeleteModal = (data) => {
    setOpen(true);
    setView(false);
    setDeleteModal(true);
    setcancelled(data._id);
  };

  const cancelstatus = () => {
    let dataPayload = {
      id: cancelledId,
      status: "cancelled",
    };

    apiManager
      .OffboardingStatus(dataPayload)
      .then((res) => {
        setLoading(false);

        setmsgType("success");
        setShowMSG(res?.data?.message || "details updated successfully");
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.data?.message || "failed to update");
      });
  };

  useEffect(() => {
    if (confirm) {
      cancelstatus();
      setConfirm(false);
      getOffboardingPendingData();
    }
  }, [confirm]);

  return (
    <div className="Container">
      <div className="col-lg-12 px-2 pt-3 tableContainer">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <Table
          columns={columns}
          LeaveTableHeight="LeaveTableHeight"
          secondary_btn_label="Delete"
          Data={displayedData}
          btn_name="view"
          handleOpenDelete={handleOpenDelete}
          onClick={(data) => {
            handleItemClick(data);
          }}
        />
      </div>
      <div className="pt-4 d-flex justify-content-end">
        <Pagination
          prev
          last
          next
          first
          size="sm"
          total={offboardingPendingData.length}
          limit={itemsPerPage}
          activePage={activePage}
          onChangePage={setActivePage}
        />
      </div>
      <CommonModal open={open} onClose={() => setOpen(false)} size={view ? "sm" : "xs"} title="Resignation Details">
        {view ? <LeaveView Data={modalContent} /> : null}
        {deleteModal ? <ConfirmationModal setOpen={setOpen} setConfirm={setConfirm} /> : null}
      </CommonModal>
      <CoreLoader show={loading} />
    </div>
  );
}
