import React, { useEffect, useState } from "react";
import { getFirstMondayOfMonth } from "../../../utils/dateFunctions";
import axios from "axios";

import Filter from "./Fiter";
import apiManager from "networking/baseAPIManager";
import Table from "./Table";
const baseURL = process.env.REACT_APP_API_URL;
const AttendanceWeekReport = () => {
  // =========================State variables========================================================
  const [reportData, setReportData] = useState();
  const [message, setmessage] = useState("");
  const [filterData, setFilterData] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    dep: "",
    pro: "",
  });

  const [startDate, setStartDate] = useState({});
  const [weekContentStartDate, setWeekContentStartDate] = useState();
  const [weekStartDate, setWeekStartDate] = useState(-7);

  // =========================Fetching Data========================================================
  // useEffect(() => {
  //   const { year, month, dep, pro } = filterData;
  //   axios
  //     .get(`http://localhost:5050/attendanceData/${parseInt(year)}/${parseInt(month)}/${dep}/${pro}`)
  //     .then((response) => {
  //       setReportData(response.data.attendanceReport.report);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setmessage("No Data Available");
  //     });
  //   const startDateData = getFirstMondayOfMonth(year, month);
  //   setStartDate(startDateData);

  //   const weekContentValue = startDateData.daysUntilMonday + 1 - 7;
  //   setWeekContentStartDate(weekContentValue);
  // }, []);
  // const weeklydata = () => {
  //   setLoading(true);

  //   apiManager

  //     .WeeklyAttendance({ year: selectedYear, month: monthNumber })
  //     .then((response) => {
  //       setLoading(false);

  //       setAttendanceListData(response.data?.List ?? []);
  //       setFilteredApprovalData(response.data?.List ?? []);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.error(error);
  //     });
  // };
  const handleSubmit = () => {
    const { year, month, dep, pro } = filterData;
    const baseURL = "http://192.168.1.6:5000/week_attendance";
    axios
      .get(baseURL)
      .then((response) => {
        setReportData(response.data);
      })
      .catch((error) => {
        // alert("Please select only the respective data or start mock-server")
        console.error(error);
        setmessage("No Data Available");
        setReportData();
      });
    const startDateData = getFirstMondayOfMonth(year, month);
    setStartDate(startDateData);
    const weekContentValue = startDateData.daysUntilMonday + 1 - 7;
    setWeekContentStartDate(weekContentValue);
  };
  // =================Paginations===========================================================
  useEffect(() => {
    if (startDate.daysUntilMonday === 0) {
      // please place this into the condition if necessary=> ||startDate.daysUntilMonday===1)
      handlePaginationUp();
    }
  }, [startDate.daysUntilMonday]);

  const handlePaginationUp = () => {
    if (weekStartDate <= startDate.lastDateOfMonth - 7 && weekContentStartDate <= startDate.lastDateOfMonth - 7) {
      setWeekContentStartDate(weekContentStartDate + 7);
      setWeekStartDate(weekStartDate + 7);
    }
  };

  const handlePaginationDown = () => {
    if (weekStartDate >= 0 && weekContentStartDate >= 0) {
      setWeekContentStartDate(weekContentStartDate - 7);
      setWeekStartDate(weekStartDate - 7);
    }
  };
  // ======= ======Render Components========================================================
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: "1.5rem",
        flexDirection: "column",
      }}
    >
      <h4>Attendance Report</h4>

      <Filter
        startDate={startDate}
        setFilterData={setFilterData}
        filterData={filterData}
        handleSubmit={handleSubmit}
        handlePaginationUp={handlePaginationUp}
        setWeekStartDate={setWeekStartDate}
      />

      <Table
        reportData={reportData}
        weekStartDate={weekStartDate}
        weekContentStartDate={weekContentStartDate}
        startDate={startDate}
        message={message}
      />
      <div
        style={{
          maxWidth: "45rem",
          display: "flex",
          justifySelf: "center",
          alignSelf: "center",
          gap: "3rem",
        }}
      >
        <div style={{ cursor: "pointer" }} onClick={() => handlePaginationDown()}>
          &lt; &lt; Previous
        </div>

        <div style={{ cursor: "pointer" }} onClick={() => handlePaginationUp()}>
          Next &gt;&gt;
        </div>
      </div>
    </div>
  );
};

export default AttendanceWeekReport;
