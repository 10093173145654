import React, { useEffect, useState } from "react";
import { Button, Input, Dropdown, AlertMessage, CoreLoader, Textarea, Label } from "common/components";
import apiManager from "networking/baseAPIManager";

function CreateRequest({ fetchapi }) {
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [selectEndDate, setselectEndDate] = useState("");
  const [selectStartDate, setSelectStartDate] = useState("");
  const [Session, setSession] = useState("");
  const [selectReason, setReason] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [showInput, setShowInput] = useState(false);
  const [errors, setErrors] = useState({});

  const leaveTypeOption = [
    { key: "session1", value: "Session 1" },
    { key: "session2", value: "Session 2" },
  ];
  const datesMatch = selectStartDate === selectEndDate;

  useEffect(() => {
    if (!datesMatch) {
      setShowInput(false);
    }
  }, [datesMatch]);

  const leaveTypes = "leaveTypes";
  const fetchLeaveTypeOptions = () => {
    setLoading(true);
    apiManager
      .getDropdownTypes(leaveTypes)
      .then((response) => {
        setLeaveTypeOptions(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching leave type options:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLeaveTypeOptions(leaveTypes);
  }, []);

  const validateForm = () => {
    const newErrors = {};

    // Required fields
    if (!selectType) newErrors.selectType = "Leave Type is required";
    if (!selectStartDate) newErrors.selectStartDate = "Start Date is required";
    if (!selectEndDate) newErrors.selectEndDate = "End Date is required";

    // Date range validation
    if (selectStartDate && selectEndDate) {
      if (new Date(selectStartDate) > new Date(selectEndDate)) {
        newErrors.dateRange = "End Date cannot be before Start Date";
      }
    }

    // Session validation
    if (showInput && !Session) newErrors.Session = "Session is required";

    // Reason required
    // if (!selectReason) newErrors.selectReason = "Reason is required";

    // File type and size validation
    if (selectedFile) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (!allowedTypes.includes(selectedFile.type)) {
        newErrors.selectedFile = "Only JPG,PNG,or PDF files are allowed";
      } else if (selectedFile.size > 2 * 1024 * 1024) {
        // 2MB limit
        newErrors.selectedFile = "File size should not exceed 5MB";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLeaveSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    let formdata = new FormData();
    formdata.append("type", selectType);
    formdata.append("start_date", selectStartDate);
    formdata.append("end_date", selectEndDate);
    formdata.append("session", Session);
    formdata.append("reason", selectReason);
    formdata.append("document", selectedFile);

    setLoading(true);
    apiManager
      .createLeave(formdata)
      .then((res) => {
        setLoading(false);
        fetchapi && fetchapi();
        setmsgType("success");
        setShowMSG(res?.data?.message || "Details updated successfully");
        resetFormFields();
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "Failed to update");
        console.log(err, "Leave Request error");
      });
  };

  const resetFormFields = () => {
    setSelectType("");
    setSelectStartDate("");
    setselectEndDate("");
    setSession("");
    setReason("");
    setSelectedFile(null);
    const fileInput = document.getElementById("attachment");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDeleteClick = () => {
    setSelectType("");
    setSelectStartDate("");
    setselectEndDate("");
    setSession("");
    setReason("");
    setSelectedFile(null);
  };

  const getLastMonthFormatted = () => {
    const currentDate = new Date();
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(currentDate.getMonth() - 1);
    const formattedDate = lastMonth.toISOString().split("T")[0];
    return formattedDate;
  };

  return (
    <>
      <div className="col-lg-12 px-5 pt-5">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <div className="pt-5 form-main">
          <div className="row pb-5">
            <div className="form-container container">
              <div className="col-lg-8 m-auto">
                <form className="leave-request">
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label label="Leave Type" parentclasses="col-lg-4 d-flex justify-content-center ps-4" required />
                    <div className="col-lg-8">
                      <Dropdown
                        value={selectType}
                        onChange={(e) => setSelectType(e.target.value)}
                        options={leaveTypeOptions}
                        placeholder="Select Leave Types"
                        sidelabel
                      />
                      {errors.selectType && <div className="text-danger ">{errors.selectType}</div>}
                    </div>
                  </div>
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label label="Start Date" parentclasses="col-lg-4 d-flex justify-content-center ps-4" required />
                    <div className="col-lg-8">
                      <Input
                        inputclasses="form-controlcustom"
                        type="date"
                        name="startDate"
                        value={selectStartDate}
                        onChange={(e) => setSelectStartDate(e.target.value)}
                        condition={getLastMonthFormatted()}
                      />
                      {errors.selectStartDate && <span className="text-danger ">{errors.selectStartDate}</span>}
                    </div>
                  </div>
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label label="End Date" parentclasses="col-lg-4 d-flex justify-content-center ps-4" required />
                    <div className="col-lg-8">
                      <Input
                        inputclasses="form-controlcustom"
                        type="date"
                        name="endDate"
                        value={selectEndDate}
                        onChange={(e) => setselectEndDate(e.target.value)}
                        min={getLastMonthFormatted()}
                        condition={selectStartDate}
                      />
                      {errors.selectEndDate && <span className="text-danger ">{errors.selectEndDate}</span>}
                      {errors.dateRange && <div className="text-danger ">{errors.dateRange}</div>}
                    </div>
                  </div>
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label label="Half-day" parentclasses="col-lg-4 d-flex justify-content-center ps-4" />
                    <Input
                      type="checkbox"
                      parentclasses="col-lg-2"
                      inputclasses="form-check-input"
                      style={{ height: "37px", width: "35px" }}
                      checked={showInput}
                      onChange={(e) => setShowInput(e.target.checked)}
                      disabled={!datesMatch}
                    />
                    {showInput && (
                      <Dropdown
                        parentclasses="col-lg-6"
                        id="dropdown"
                        name="session"
                        value={Session}
                        onChange={(e) => setSession(e.target.value)}
                        options={leaveTypeOption}
                        placeholder="Select Session"
                        datesMatch={selectStartDate === selectEndDate}
                      />
                    )}
                    {errors.Session && <span className="text-danger ">{errors.Session}</span>}
                  </div>
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label label="Reason" parentclasses="col-lg-4 d-flex justify-content-center" required />
                    <div className="col-lg-8">
                      <Textarea
                        name="reason"
                        value={selectReason}
                        onChange={(e) => setReason(e.target.value)}
                        maxLength={200}
                      />
                      {errors.selectReason && (
                        <div className="text-danger  d-flex justify-content-center">{errors.selectReason}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label label="Attachment" parentclasses="col-lg-4 d-flex justify-content-center ps-5" />
                    <div className="col-lg-8">
                      <Input
                        inputclasses="form-controlcustom"
                        type="file"
                        id="attachment"
                        name="attachment"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                        sidelabel
                      />
                      {errors.selectedFile && <div className="text-danger ">{errors.selectedFile}</div>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
            <Button onClick={handleLeaveSubmit} variant="primary" type="submit" label="Submit" />
            <Button onClick={handleDeleteClick} variant="primary" type="button" label="Cancel" />
          </div>
        </div>
      </div>
      <CoreLoader show={loading} disableLines />
    </>
  );
}

export default CreateRequest;
